/* eslint-disable react/no-unstable-nested-components */
import React, { memo, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { CustomInputWithController } from "../../Core/Components/CustomInput";
import { client as axios } from "../../Utils/axiosClient";
import Button from "../../Core/Components/CustomButton";
import { updateLoggedInUser } from "../../Redux/Slices/loggedInUser.slice";
import BlockedEyeIcon from "../../Common/Svgs/BlockedEyeIcon";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { handleSubmit, control, formState, setError } = useForm({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loginFocus, setLoginFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const onSubmit = async (e) => {
    const { email, password } = e;

    try {
      dispatch(
        updateLoggedInUser({
          key: "loading",
          value: true,
        }),
      );

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          email,
          password,
        },
      );

      if (response.data.token) {
        const { message, token, ...rest } = response.data;

        dispatch(updateLoggedInUser({ key: "userDetails", value: rest }));

        localStorage.setItem("token", response.data.token);

        navigate("/home");
      }
    } catch (error) {
      setError(error.response.data.type, {
        type: "server",
        message: error.response.data.message,
      });
    } finally {
      dispatch(
        updateLoggedInUser({
          key: "loading",
          value: false,
        }),
      );
    }
  };

  const validationForEmail = {
    required: "please enter email address",
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
      message: "Enter a vaild email",
    },
  };

  const validationForPassword = {
    required: "please enter password",
    minLength: {
      value: 8,
      message: "less characters for password",
    },
  };

  // if token exist the there is no need to login again
  useEffect(() => {
    const tokenExist = localStorage.getItem("token");
    if (tokenExist) {
      navigate("/home");
    }
  }, [navigate]);

  return (
    <div className="w-screen h-screen">
      <Row className="h-full">
        <Col span={15} className="h-full overflow-hidden">
          <img
            src="/assets/images/Login/login-image.svg"
            alt="LoginImage"
            className="object-cover w-full h-full"
          />
        </Col>
        <Col span={9}>
          <div className="flex flex-col items-center justify-center w-full h-full gap-y-6 font-poppins bg-background-white">
            <img
              src="/assets/songa-main-logo.svg"
              alt="Songa Mobility"
              className="w-[258px] h-14"
            />
            <div>
              <div className="my-[22px] text-xs font-light leading-4.5 text-center text-dark-gray">
                Login to continue to dashboard
              </div>
            </div>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col items-center justify-center w-full gap-y-6"
            >
              <CustomInputWithController
                intent="login"
                control={control}
                formState={formState}
                focus={loginFocus}
                setFocus={setLoginFocus}
                name="email"
                placeholder="Email Address"
                rules={validationForEmail}
              />

              <CustomInputWithController
                inputType={showPassword ? "text" : "password"}
                intent="login"
                control={control}
                formState={formState}
                focus={passwordFocus}
                setFocus={setPasswordFocus}
                name="password"
                placeholder="Password"
                rules={validationForPassword}
                suffix={
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <img src="/images/eye.png" className="w-5 h-5" alt="" />
                    ) : (
                      <BlockedEyeIcon className="text-black" />
                    )}
                  </button>
                }
              />

              <Button type="submit" width="login">
                Login
              </Button>

              <button
                type="button"
                onClick={() => navigate("/reset-password")}
                className="cursor-pointer font-poppins font-light text-xs leading-4.5 text-dark-gray hover:text-black hover:underline"
              >
                Reset Password
              </button>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default memo(Login);
