/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import CustomDetailsLayout from "../../Core/Layout/CustomDetailsLayout";
import { KPIData, trikesDetailsColumns } from "./Data/TrikesDetailData";
import { CustomDetailsKPI } from "../../Core/Components/CustomKpi";
import CustomTable from "../../Core/Components/CustomTable";
import CustomProgressBar from "../../Core/Components/CustomProgressBar";
import CustomRoundedImage from "../../Core/Components/CustomRoundedImage";
import TrikeIcon from "../../Common/Svgs/TrikeIcon";
import { BatteryPackIcon } from "../../Common/Svgs/BatteryIcon";
import {
  getTripsForDriverV3,
  getTripsForTrikeV3,
} from "../../Redux/APIs/tripsAPI";
import ViewDetailsDrawer from "../Users/ViewDetailsDrawer";
import { getTrikeByIdV3 } from "../../Redux/APIs/trikesAPI";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";

const TrikesDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { currentTrike } = useSelector((state) => state.trikes);

  const { tripsForTrike } = useSelector((state) => state.trips);

  const { batteryDetails } = useSelector((state) => state.batteries);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [viewDetailsDrawer, setViewDetailsDrawer] = useState(false);

  useEffect(() => {
    dispatch(getTrikeByIdV3({ id: currentTrike.id }));
    dispatch(getTripsForTrikeV3({ trikeId: currentTrike.id }));

    if (currentTrike.currentDriverId !== null) {
      dispatch(getTripsForDriverV3({ driverId: currentTrike.currentDriverId }));
    }
  }, [dispatch]);

  // fetching current trike details every 15 seconds for getting the live location
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getTrikeByIdV3({ id: currentTrike.id }));
    }, 15000);

    return () => clearInterval(interval);
  }, [dispatch]);

  console.log(currentTrike, "check current trike");

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <CustomLayout pageName="Trikes" nestedName={`${id}`}>
      <CustomDetailsLayout
        assetType="trikes"
        KPIBody={
          <div className="bg-background-white">
            <Row gutter={24} className="h-[360px] p-6">
              <Col span={10}>
                <div className="flex flex-col justify-start w-full h-full items-between gap-y-2">
                  {KPIData(currentTrike, batteryDetails)?.map((i) => (
                    <CustomDetailsKPI
                      key={i.id}
                      title={i.title}
                      value={i.value}
                      image={i.image}
                      background={i.background}
                    />
                  ))}
                </div>
              </Col>
              <Col span={14}>
                <div className="w-full h-full border-[0.5px] border-light-grey rounded-md p-4 flex flex-col justify-between">
                  <div className="flex items-center justify-between">
                    <div className="flex justify-start items-center gap-x-2.5">
                      <CustomRoundedImage
                        background="bg-primary"
                        image={<BatteryPackIcon className="text-white" />}
                      />
                      <div className="font-aileron font-normal text-dark-gray text-xs leading-4.5">
                        Battery Pack
                      </div>
                    </div>
                    <div className="flex justify-start items-start gap-x-2.5">
                      <img
                        src="/assets/icons/details/discharging-grey.svg"
                        alt=""
                      />
                      <div className="font-aileron font-normal text-dark-gray text-xs leading-4.5">
                        Discharging
                      </div>
                    </div>
                  </div>
                  <div className="grid items-center grid-cols-5">
                    <div className="flex flex-col col-span-1">
                      <div className="font-aileron font-semibold text-2xl leading-9 text-center py-2.25 relative z-10">
                        {currentTrike.batteryPack}%
                      </div>
                      <div className="w-[106px] h-[198px]">
                        <CustomProgressBar
                          text=""
                          current={
                            currentTrike.batteryPack
                              ? currentTrike?.batteryPack
                              : "N/A"
                          }
                          color="bg-primary"
                          textColor="text-white"
                        />
                      </div>
                    </div>
                    <img
                      src="/assets/icons/details/arrow-full.svg"
                      alt=""
                      className="mx-auto mt-[46px]"
                    />
                    <div className="col-span-3 pr-[2px]">
                      <div className="mt-5">
                        <div className="font-aileron font-light text-dark-gray text-xs leading-4.5">
                          Batteries
                        </div>
                        <div className="flex items-center justify-between mt-2">
                          <div className="flex flex-col items-center gap-y-1">
                            <p className="text-xs font-aileron font-seimbold text-dark-gray">
                              {currentTrike.batteryPack
                                ? `${currentTrike?.batteryPack} %`
                                : "N/A"}
                            </p>

                            <div
                              className="w-[74px] h-[182px] cursor-pointer"
                              // onClick={() => {
                              //   if (
                              //     currentTrike?.associatedBatteries[0] !== null
                              //   ) {
                              //     navigate(
                              //       `/batteries/${currentTrike?.associatedBatteries[0]}`,
                              //     );
                              //   }
                              // }}
                            >
                              <CustomProgressBar
                                text="B1"
                                current={currentTrike?.batteryPack || 0}
                                color="bg-battery-orange"
                              />
                            </div>
                          </div>
                          <img
                            src="/assets/icons/details/add.svg"
                            alt=""
                            className=""
                          />
                          <div className="flex flex-col items-center gap-y-1">
                            <p className="text-xs font-aileron font-seimbold text-dark-gray">
                              {currentTrike.batteryPack
                                ? `${currentTrike?.batteryPack} %`
                                : "N/A"}
                            </p>
                            <div
                              className="w-[74px] h-[182px] cursor-pointer"
                              // onClick={() => {
                              //   if (
                              //     currentTrike?.associatedBatteries[1] !== null
                              //   ) {
                              //     navigate(
                              //       `/batteries/${currentTrike?.associatedBatteries[1]}`,
                              //     );
                              //   }
                              // }}
                            >
                              <CustomProgressBar
                                text="B2"
                                current={currentTrike?.batteryPack || 0}
                                color="bg-primary-20"
                              />
                            </div>
                          </div>

                          <img src="/assets/icons/details/add.svg" alt="" />
                          <div className="flex flex-col items-center gap-y-1">
                            <p className="text-xs font-aileron font-seimbold text-dark-gray">
                              {currentTrike.batteryPack
                                ? `${currentTrike?.batteryPack} %`
                                : "N/A"}
                            </p>
                            <div
                              className="w-[74px] h-[182px] cursor-pointer"
                              // onClick={() => {
                              //   if (
                              //     currentTrike?.associatedBatteries[2] !== null
                              //   ) {
                              //     navigate(
                              //       `/batteries/${currentTrike?.associatedBatteries[2]}`,
                              //     );
                              //   }
                              // }}
                            >
                              <CustomProgressBar
                                text="B3"
                                current={currentTrike?.batteryPack || 0}
                                color="bg-light-alert-red"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        }
        TableBody={
          <div className="bg-background-white border-y border-light-grey">
            <div className="flex items-center gap-x-3 px-6 py-4">
              <p className="font-poppins text-base font-medium">Trips</p>
              <div className="flex items-center justify-center rounded-full w-6 h-6 bg-light-grey">
                <p className="font-aileron text-10 font-semibold">
                  {tripsForTrike?.length || "0"}
                </p>
              </div>
            </div>

            <div className="w-full h-[1px] bg-light-grey" />

            <CustomTable
              // scroll={`${
              //   showKpi ? "calc(100vh - 607.5px)" : "calc(100vh - 506px)"
              // }`}
              scrollX={0}
              columns={trikesDetailsColumns}
              data={tripsForTrike}
              emptyStateHeight={`${"h-[calc(100vh-226px)]"}`}
              emptyState={<TrikeIcon size={16} className="text-dark-gray" />}
              customTableClassName="w-full"
            />
          </div>
        }
      />
      <div className="">
        <ViewDetailsDrawer
          visible={viewDetailsDrawer}
          onCancel={() => setViewDetailsDrawer(false)}
        />
      </div>
    </CustomLayout>
  );
};
export default TrikesDetails;
