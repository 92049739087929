/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../../Core/Components/CustomTable";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import { KPIData } from "../Data/TrikesListData";
import CustomKPI from "../../../Core/Components/CustomKpi";
import {
  THREE_DOTS,
  TRIKES_ID_NUMBER,
  allColumns,
} from "../Data/TrikesColumnConstants";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";
import {
  getChecklist,
  getTrikesKPI,
  getTrikesV3,
} from "../../../Redux/APIs/trikesAPI";
import { updateTrikes } from "../../../Redux/Slices/trikes.slice";
import TrikesListHeader from "./TrikesListHeader";
import QRCodeModal from "../../../Core/Components/Modal/QRCodeModal";
import { updateQrCode } from "../../../Redux/Slices/qrcode.slice";
import ToastMessageModal from "./ToastMessageModal";
import { DEFAULT_LIMIT } from "../../../Utils/constants";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import ChecklistModal from "../../../Core/Components/Modal/ChecklistModal";
import MobiliseModalV3 from "../../../Core/Components/Modal/MobiliseModalV3";
import DeleteTrikeModal from "./DeleteTrikeModal";
import TrikeTrackerModal from "./TrikeTrackerModal";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import { getUserByEmail } from "../../../Redux/APIs/systemUsersAPI";

function TrikesListV2() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    selectedColumns,
    selectedRowIds,
    trikeListV3,
    showToastMessage,
    kpiData,
    checklistModal,
    mobiliseModalV3,
    deleteTrikeModal,
    trikeTrackerModal,
  } = useSelector((state) => state.trikes);

  const { viewModal } = useSelector((state) => state.qrCode);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  // service permission
  const hasServicePermission = usePermission([
    PERMISSIONS.SERVICE_VIEW,
    PERMISSIONS.SERVICE_VIEW_AND_EDIT,
  ]);

  // mobilise permission
  const hasMobilisePermission = usePermission([PERMISSIONS.TRIKE_MOBILISE]);

  // tracker permission
  const hasTrackerPermission = usePermission([PERMISSIONS.TRACKER_SWITCH]);

  const [showKpi, setShowKpi] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleMoreColumns = () => {
    let localColumns = [...selectedColumns];

    // Check for mobilise permission
    if (hasMobilisePermission) {
      localColumns = [...new Set([...localColumns, "TRIKES_ON_OFF"])];
    } else {
      localColumns = localColumns.filter((item) => item !== "TRIKES_ON_OFF");
    }

    // Check for tracker permission
    if (hasTrackerPermission) {
      localColumns = [...new Set([...localColumns, "TRIKES_TRACKER"])];
    } else {
      localColumns = localColumns.filter((item) => item !== "TRIKES_TRACKER");
    }

    // Dispatch once with the final columns
    dispatch(
      updateTrikes({
        key: "selectedColumns",
        value: localColumns,
      }),
    );
  };

  const handleRowClick = (record) => {
    dispatch(
      updateTrikes({
        key: "currentTrike",
        value: trikeListV3.result.find((item) => item.id === record.id),
      }),
    );
    navigate(`/trikes/${record.registrationNo}`);
  };

  useEffect(() => {
    console.log(window.ResizeObserver.prototype);
  }, [window]);

  useEffect(() => {
    dispatch(getTrikesV3({ offset: ((currentPage || 1) - 1) * DEFAULT_LIMIT }));
    dispatch(getTrikesKPI());
    dispatch(getChecklist());
  }, [dispatch, currentPage]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateTrikes({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  // to handle mobilise and tracker column updation
  useEffect(() => {
    handleMoreColumns();
  }, [hasMobilisePermission, hasTrackerPermission]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, []);

  return (
    <CustomLayout pageName="Trikes">
      <div className="relative bg-background">
        <div className="flex items-center justify-between w-full h-40 p-6 gap-x-5.5">
          {KPIData(kpiData)?.map((i) => (
            <CustomKPI
              key={i?.id}
              image={i?.image}
              title={i?.title}
              count={i?.count}
              total={i?.total}
              icon={hasServicePermission && i?.icon}
              background={i?.background}
              handleOnClick={i.onClick}
              redirect={hasServicePermission && i?.id === 3}
            />
          ))}
        </div>

        <div
          className={`absolute w-[calc(100vw-72px)] ${
            showKpi ? "-translate-y-[160px]" : ""
          }  transition-transform duration-500`}
        >
          <TrikesListHeader
            showKpi={showKpi}
            setShowKpi={setShowKpi}
            currentPage={currentPage}
          />

          <CustomTable
            // scroll={`${
            //   showKpi ? "calc(100vh - 200px)" : "calc(100vh - 362px)"
            // }`}
            module="trike-listing"
            isKpiVisible={!showKpi}
            scrollX="600px"
            scrollY={290}
            // trike id number will always be the first column,
            // other columns (as selected from the dropdown will be added on the right)
            // three dots columns will always be the last column
            columns={[
              TRIKES_ID_NUMBER,
              ...allColumns
                .filter((item) => selectedColumns.includes(item.key))
                .map((column) => column.value),
              THREE_DOTS,
            ]}
            emptyState={<TrikeIcon size={16} className="text-dark-gray" />}
            data={trikeListV3.result}
            onRowClick={handleRowClick}
            rowClassName={(record) => {
              return selectedRowIds.includes(record.id)
                ? "selected-row group cursor-pointer"
                : "group cursor-pointer";
            }}
            pagination={{
              pageSize: 10,
              total: trikeListV3.totalRecords,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              itemRender: (current, type, originalElement) => {
                const lastPage = trikeListV3.totalRecords / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }
                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>
      </div>

      <MobiliseModalV3
        visible={mobiliseModalV3.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "mobiliseModalV3",
              value: {
                visibe: false,
                trike: {},
              },
            }),
          )
        }
      />

      <QRCodeModal
        visible={viewModal?.visible}
        type={viewModal?.data?.assetType}
        onCancel={() =>
          dispatch(
            updateQrCode({
              key: "viewModal",
              value: { visible: false, data: {} },
            }),
          )
        }
      />

      <ChecklistModal
        visible={checklistModal.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "checklistModal",
              value: { visible: false, checklist: [] },
            }),
          )
        }
      />

      <DeleteTrikeModal
        visible={deleteTrikeModal?.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "deleteTrikeModal",
              value: {
                visible: false,
                id: 0,
                registrationNo: "",
              },
            }),
          )
        }
        trikeCurrentPage={currentPage}
      />

      <TrikeTrackerModal
        visible={trikeTrackerModal?.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "trikeTrackerModal",
              value: {
                visible: false,
                trikeId: 0,
                trikeRegistrationNo: "",
                currentSource: 1,
              },
            }),
          )
        }
        page="trike-listing"
        currentPage={currentPage}
      />

      <ToastMessageModal
        visible={showToastMessage.visible}
        onCancel={() =>
          dispatch(
            updateTrikes({
              key: "showToastMessage",
              value: { visible: false, data: {} },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
        icon={showToastMessage?.data?.icon}
      />
    </CustomLayout>
  );
}
export default TrikesListV2;
