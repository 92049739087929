/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line import/no-named-as-default
import React from "react";
import dayjs from "dayjs";
import BatteryPercentage from "../../../Core/Components/BatteryPercentage";
import CustomTooltip from "../../../Core/Components/CustomTooltip";
import getDateAndTime from "../../../Utils/getDateAndTime";
import getTrikeStatus from "../phase3/getTrikeStatus";
import ListDropdown from "../phase3/ListDropdown";
import SubmittedDetails from "../../../Core/Components/SubmittedDetails";
import { ChecklistCTA } from "../../../Core/Components/Modal/ChecklistModal";
import { MobiliseModalCTA } from "../../../Core/Components/Modal/MobiliseModalV3";
import TrikeTrackerSwitch from "../../../Core/Components/TrikeTrackerSwitch";

export const TRIKES_ID_NUMBER = {
  title: () => (
    <div className="font-light font-aileron text-10 text-dark-gray">
      Registration No.
    </div>
  ),
  dataIndex: "registrationNo",
  fixed: "left",
  width: "150px",
  sorter: (a, b) => a.registrationNo.localeCompare(b.registrationNo),
  render: (text) => (
    <div className="text-xs font-semibold font-aileron leading-4.5 text-black group-hover:underline group-hover:text-new-black">
      {text}
    </div>
  ),
};

export const TRIKES_DRIVER_NAME = {
  title: () => (
    <div className="font-light font-aileron text-10 text-dark-gray">Driver</div>
  ),
  dataIndex: "driverFirstName",
  fixed: "left",
  width: "150px",
  sorter: (a, b) => a?.driverFirstName?.localeCompare(b?.driverFirstName),
  render: (text, record) => (
    <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
      {text ? `${text} ${record.driverLastName}` : "--"}
    </div>
  ),
};

export const TRIKES_MODEL_NO = {
  title: () => (
    <div className="font-light font-aileron text-10 text-dark-gray">
      Model no.
    </div>
  ),
  dataIndex: "id",
  width: "150px",
  // sorter: (a, b) => a.id.localeCompare(b.id),
  render: (text, record) => (
    <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray group-hover:underline group-hover:text-new-black capitalize">
      {record.modelType}
    </div>
  ),
};

export const TRIKES_BATTERY_PACK = {
  title: () => (
    <div className="font-light font-aileron text-10 text-dark-gray">
      Battery Pack
    </div>
  ),
  dataIndex: "batteryPercentage",
  width: "150px",
  // sorter: (a, b) => a.battInPercentage - b.battInPercentage,
  render: (text, record) => (
    // <CustomTooltip
    //   tooltipTitle={`As on ${getDateAndTime(record.lastOnline)}`}
    //   placement="topLeft"
    // >
    //   <div>
    //     <BatteryPercentage percentage={text || -1} chargingStatus={false} />
    //   </div>
    // </CustomTooltip>
    <div>
      {record.lastOnline ? (
        <CustomTooltip
          tooltipTitle={`As on ${getDateAndTime(record.lastOnline)}`}
          placement="topLeft"
        >
          <div>
            <BatteryPercentage percentage={text || -1} chargingStatus={false} />
          </div>
        </CustomTooltip>
      ) : (
        <div>
          <BatteryPercentage percentage={text || -1} chargingStatus={false} />
        </div>
      )}
    </div>
  ),
};

export const TRIKES_CURRENT_SPEED = {
  title: () => (
    <div className="font-light font-aileron text-10 text-dark-gray">
      Current Speed
    </div>
  ),
  dataIndex: "currentSpeed",
  width: "150px",
  // sorter: (a, b) => a.currentSpeed - b.currentSpeed,
  render: (text, record) => (
    <div>
      {record.lastOnline ? (
        <CustomTooltip
          tooltipTitle={`As on ${getDateAndTime(record.lastOnline)}`}
          placement="topLeft"
        >
          <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
            {text ? `${text} Km/H` : " --"}
          </div>
        </CustomTooltip>
      ) : (
        <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
          {text ? `${text} Km/H` : " --"}
        </div>
      )}
    </div>
  ),
};

export const TRIKES_STATUS = {
  title: () => (
    <div className="font-light font-aileron text-10 text-dark-gray">Status</div>
  ),
  dataIndex: "status",
  width: "150px",
  render: (text) => <div>{getTrikeStatus(+text)}</div>,
};
export const TRIKES_ON_OFF = {
  title: () => (
    <div className="font-light font-aileron text-10 text-dark-gray">On/Off</div>
  ),
  dataIndex: "isMoving",
  width: "150px",
  render: (text, record) => <MobiliseModalCTA trike={record} />,
};

export const TRIKES_CURRENT_PAYLOAD = {
  title: () => (
    <div className="font-light font-aileron text-10 text-dark-gray">
      Current Payload
    </div>
  ),
  dataIndex: "currentPayload",
  sorter: (a, b) => a.currentPayload.localeCompare(b.currentPayload),
  width: "100px",
  render: (text, record) => (
    <div>
      {record.lastOnline ? (
        <CustomTooltip
          tooltipTitle={`As on ${getDateAndTime(record.lastOnline)}`}
          placement="topLeft"
        >
          <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
            {text}
          </div>
        </CustomTooltip>
      ) : (
        <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
          {text}
        </div>
      )}
    </div>
  ),
};

// export const TRIKES_USAGE = {
//   title: () => <div className="font-light font-poppins text-10 text-dark-gray">Usage</div>,
//   sorter: (a, b) => a.usage - b.usage,
//   dataIndex: 'usage',
//   width: '150px',
//   render: (text, record) => (
//     <div className="flex justify-between">
//       <CustomTooltip
//         tooltipTitle={`As on ${getDateAndTime(record.submittedAt)}`}
//         placement="topLeft"
//       >
//         <div className="text-xs font-medium font-poppins leading-4.5 text-dark-gray">
//           {text}
//         </div>
//       </CustomTooltip>
//     </div>
//   ),
// };

// export const TRIKES_TOTAL_PAYLOAD = {
//   title: () => (
//     <div className="font-light font-poppins text-10 text-dark-gray">
//       Total Payload
//     </div>
//   ),
//   sorter: (a, b) => a.totalPayload.localeCompare(b.totalPayload),
//   dataIndex: 'totalPayload',
//   width: '150px',
//   render: (text, record) => (
//     <CustomTooltip
//       tooltipTitle={`As on ${getDateAndTime(record.submittedAt)}`}
//       placement="topLeft"
//     >
//       <div className="text-xs font-semibold font-poppins leading-4.5 text-dark-gray">
//         {text}
//       </div>
//     </CustomTooltip>
//   ),
// };

export const TRIKES_CHECKLIST = {
  title: () => (
    <div className="font-light font-poppins text-10 text-dark-gray">
      Checklist
    </div>
  ),
  dataIndex: "checklist",
  width: "150px",
  onCell: () => {
    return {
      onClick: (event) => {
        event.stopPropagation();
      },
    };
  },
  render: (text, record) => <ChecklistCTA record={record} />,
};

export const TRIKES_DISTANCE = {
  title: () => (
    <div className="font-light font-poppins text-10 text-dark-gray">
      Distance
    </div>
  ),
  sorter: (a, b) => a.distance.localeCompare(b.distance),
  dataIndex: "distance",
  width: "150px",
  render: (text, record) => (
    <CustomTooltip
      tooltipTitle={`As on ${getDateAndTime(record.submittedAt)}`}
      placement="topLeft"
    >
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text} Km
      </div>
    </CustomTooltip>
  ),
};

export const TRIKES_MILEAGE = {
  title: () => (
    <div className="font-light font-poppins text-10 text-dark-gray">
      Mileage
    </div>
  ),
  sorter: (a, b) => a.mileage.localeCompare(b.mileage),
  dataIndex: "mileage",
  width: "150px",
  render: (text, record) => (
    <CustomTooltip
      tooltipTitle={`As on ${getDateAndTime(record.submittedAt)}`}
      placement="topLeft"
    >
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text}
      </div>
    </CustomTooltip>
  ),
};

export const TRIKES_AVERAGE_SPEED = {
  title: () => (
    <div className="font-light font-poppins text-10 text-dark-gray">
      Average Speed
    </div>
  ),
  dataIndex: "averageSpeed",
  width: "150px",
  render: (text, record) => (
    <CustomTooltip
      tooltipTitle={`As on ${getDateAndTime(record.submittedAt)}`}
      placement="topLeft"
    >
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text ? `${text} Km/H` : " --"}
      </div>
    </CustomTooltip>
  ),
};

export const TRIKES_CHASSIS_NO = {
  title: () => (
    <div className="font-light font-poppins text-10 text-dark-gray">
      Chassis Number
    </div>
  ),
  sorter: (a, b) => a.chassisNo.localeCompare(b.chassisNo),
  dataIndex: "chassisNo",
  width: "150px",
  render: (text) => (
    <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
      {text}
    </div>
  ),
};

export const TRIKES_VEHICLE_TYPE = {
  title: () => (
    <div className="font-light font-poppins text-10 text-dark-gray">
      Vehicle Type
    </div>
  ),
  // sorter: (a, b) => a.chassisNo.localeCompare(b.chassisNo),
  dataIndex: "vehicleType",
  width: "150px",
  render: (text) => (
    <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray uppercase">
      {text}
    </div>
  ),
};

export const TRIKES_ASSET_TAG = {
  title: () => (
    <div className="font-light font-poppins text-10 text-dark-gray">
      Asset Tag
    </div>
  ),
  sorter: (a, b) => a.assetTag.localeCompare(b.assetTag),
  dataIndex: "assetTag",
  width: "150px",
  render: (text) => (
    <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
      {text}
    </div>
  ),
};

export const TRIKES_INSURANCE_VALIDITY = {
  title: () => (
    <div className="font-light font-poppins text-10 text-dark-gray">
      Insurance Validity
    </div>
  ),
  // sorter: (a, b) => a.chassisNo.localeCompare(b.chassisNo),
  dataIndex: "insuranceValidity",
  width: "150px",
  render: (text) => (
    <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray uppercase">
      {dayjs(text).format("DD-MM-YYYY")}
    </div>
  ),
};

export const TRIKES_DATE_OF_ENTRY = {
  title: () => (
    <div className="font-light font-poppins text-10 text-dark-gray">
      Date of Entry
    </div>
  ),
  sorter: (a, b) => a.submittedBy.localeCompare(b.submittedBy),
  dataIndex: "submittedBy",
  width: "200px",
  render: (text, record) => <SubmittedDetails record={record} />,
};

export const TRIKES_TRACKER = {
  title: () => (
    <div className="font-light font-poppins text-10 text-dark-gray">
      Tracker
    </div>
  ),
  // sorter: (a, b) => a.submittedBy.localeCompare(b.submittedBy),
  dataIndex: "locationFetchSource",
  width: "150px",
  render: (text, record) => (
    <div
      role="button"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <TrikeTrackerSwitch
        trikeId={record.id}
        trikeRegistrationNo={record.registrationNo}
        locationFetchSource={text}
      />
    </div>
  ),
};

export const THREE_DOTS = {
  dataIndex: "id",
  fixed: "right",
  width: "100px",
  onCell: () => {
    return {
      onClick: (event) => event.stopPropagation(),
    };
  },
  render: (text, record) => (
    <div
      role="button"
      className="w-full flex items-center justify-center"
      // onClick={(event) => {
      //   event.stopPropagation();
      // }}
    >
      <ListDropdown detail={record} />
    </div>
  ),
};

export const allColumns = [
  {
    key: "TRIKES_DRIVER_NAME",
    value: TRIKES_DRIVER_NAME,
  },
  {
    key: "TRIKES_BATTERY_PACK",
    value: TRIKES_BATTERY_PACK,
  },
  {
    key: "TRIKES_CURRENT_SPEED",
    value: TRIKES_CURRENT_SPEED,
  },
  {
    key: "TRIKES_STATUS",
    value: TRIKES_STATUS,
  },
  {
    key: "TRIKES_ON_OFF",
    value: TRIKES_ON_OFF,
  },
  {
    key: "TRIKES_CURRENT_PAYLOAD",
    value: TRIKES_CURRENT_PAYLOAD,
  },
  {
    key: "TRIKES_TRACKER",
    value: TRIKES_TRACKER,
  },
  {
    key: "TRIKES_MODEL_NO",
    value: TRIKES_MODEL_NO,
  },
  {
    key: "TRIKES_CHECKLIST",
    value: TRIKES_CHECKLIST,
  },
  {
    key: "TRIKES_DISTANCE",
    value: TRIKES_DISTANCE,
  },
  {
    key: "TRIKES_MILEAGE",
    value: TRIKES_MILEAGE,
  },
  {
    key: "TRIKES_AVERAGE_SPEED",
    value: TRIKES_AVERAGE_SPEED,
  },
  {
    key: "TRIKES_CHASSIS_NO",
    value: TRIKES_CHASSIS_NO,
  },
  {
    key: "TRIKES_VEHICLE_TYPE",
    value: TRIKES_VEHICLE_TYPE,
  },
  {
    key: "TRIKES_ASSET_TAG",
    value: TRIKES_ASSET_TAG,
  },
  {
    key: "TRIKES_INSURANCE_VALIDITY",
    value: TRIKES_INSURANCE_VALIDITY,
  },
  {
    key: "TRIKES_DATE_OF_ENTRY",
    value: TRIKES_DATE_OF_ENTRY,
  },
  {
    key: "THREE_DOTS",
    value: THREE_DOTS,
  },
];
