/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-expressions */
import React from "react";

import BatteryPercentage from "../../../Core/Components/BatteryPercentage";
import CustomTooltip from "../../../Core/Components/CustomTooltip";
import getDateAndTime from "../../../Utils/getDateAndTime";
import getBatteryStatus from "../../../Utils/getBatteryStatus";
import BatteryIcon from "../../../Common/Svgs/BatteryIcon";
import TrikeIcon from "../../../Common/Svgs/TrikeIcon";
import ServiceIcon from "../../../Common/Svgs/ServiceIcon";
// import CheckboxCTA, { UnselectAll } from '../phase3/CheckboxCTA';
import ListDropdown from "../phase3/ListDropdown";
import EditIcon from "../../../Common/Svgs/EditIcon";
import TrashIcon from "../../../Common/Svgs/TrashIcon";
import ViewDetailsIcon from "../../../Common/Svgs/ViewDetailsIcon";
import AddToServiceIcon from "../../../Common/Svgs/AddToServiceIcon";
import { BATTERY_STATUS } from ".";
import { BatteryNoStatusIcon } from "../../../Common/Svgs/BatteryChargeIcon";
import SubmittedDetails from "../../../Core/Components/SubmittedDetails";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";

export const batteriesColumns = [
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Registration no.
      </div>
    ),
    dataIndex: "registrationNo",
    sorter: (a, b) => a.registrationNo.localeCompare(b.registrationNo),
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-black group-hover:underline group-hover:text-black">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Tag number
      </div>
    ),
    dataIndex: "tagNo",
    // sorter: (a, b) => a.associatedWith - b.associatedWith,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Charger Type
      </div>
    ),
    dataIndex: "chargerType",
    // sorter: (a, b) => a.associatedWith - b.associatedWith,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Battery Type
      </div>
    ),
    dataIndex: "type",
    // sorter: (a, b) => a.associatedWith - b.associatedWith,
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text || "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Status
      </div>
    ),
    dataIndex: "status",
    width: "200px",
    render: (text) => getBatteryStatus(text),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Associated With
      </div>
    ),
    dataIndex: "id",
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray">
        {record.trikeRegistrationNo
          ? record.trikeRegistrationNo
          : record.locationName}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Battery Percentage
      </div>
    ),
    dataIndex: "percentage",
    sorter: (a, b) => a.percentage - b.percentage,
    render: (text, record) => (
      <CustomTooltip
        tooltipTitle={`As on ${getDateAndTime(record.submittedAt)}`}
        placement="topLeft"
      >
        {record.status !== BATTERY_STATUS.ASSEMBLY_STATION ? (
          <div>
            <BatteryPercentage percentage={text} chargingStatus="discharging" />
          </div>
        ) : (
          <div className="flex items-center gap-x-2">
            <BatteryNoStatusIcon />
            <p>--</p>
          </div>
        )}
      </CustomTooltip>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Driver
      </div>
    ),
    dataIndex: "driverFirstName",
    sorter: (a, b) => a?.driverFirstName?.localeCompare(b?.driverFirstName),
    render: (text, record) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text ? `${text} ${record.driverLastName}` : "--"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Date of Entry
      </div>
    ),
    dataIndex: "submittedBy",
    render: (text, record) => (
      <div>
        <SubmittedDetails record={record} />
      </div>
    ),
  },
  {
    dataIndex: "id",
    width: "100px",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="w-full flex items-center justify-center invisible group-hover:visible"
        // invisible group-hover:visible
      >
        <ListDropdown detail={record} />
      </button>
    ),
  },
];

export const batteriesDropdownBtnArr = [
  {
    title: "Battery %",
    renderBody: "Battery %",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "percentage",
  },
  {
    title: "Driver",
    renderBody: "Driver",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "drivers",
  },
  {
    title: "Status",
    renderBody: "BatteryStatus",
    size: "filter",
    color: "white",
    padding: "filter",
    filters: "status",
  },
];

export const batteriesMoreBtnArr = [
  {
    title: "More Filters",
    size: "filter",
    color: "white",
    padding: "filter",
    icon: "sort.svg",
  },
];

export const BatteryKPIData = (kpiData, navigate, callBack) => {
  const hasServicePermission = usePermission([
    PERMISSIONS.SERVICE_VIEW,
    PERMISSIONS.SERVICE_VIEW_AND_EDIT,
  ]);

  return [
    {
      id: 1,
      title: "No. of Batteries in Assembly Station",
      count: kpiData.batteriesInAssemblyStation || 0,
      total: kpiData.total,
      image: <BatteryIcon className="w-4 h-4 fill-none stroke-kpi-icon" />,
      onClick: () => {},
    },
    {
      id: 2,
      title: "No. of Batteries in trikes",
      count: kpiData.batteriesInTrike || 0,
      total: kpiData.total,
      image: <TrikeIcon size={16} className="text-kpi-icon" />,
      onClick: () => {},
    },
    {
      id: 3,
      title: "No. of Batteries in Service",
      count: kpiData.batteriesInService || 0,
      image: <ServiceIcon className="w-4 h-4 fill-none stroke-kpi-icon" />,
      icon: (
        <img
          src="/assets/icons/chevron-down.svg"
          className="-rotate-90 "
          alt="maintainance"
        />
      ),
      onClick: () => {
        if (hasServicePermission) {
          navigate("/service");
          callBack();
        }
      },
    },
  ];
};

export const associationForBatteries = [
  {
    value: 1,
    label: (
      <div className="font-poppins text-xs leading-4.5 font-medium text-dark-gray hover:text-new-black">
        Trike
      </div>
    ),
  },
  {
    value: 2,
    label: (
      <div className="font-poppins text-xs leading-4.5 font-medium text-dark-gray hover:text-new-black">
        Swap Station
      </div>
    ),
  },
  {
    value: 3,
    label: (
      <div className="font-poppins text-xs leading-4.5 font-medium text-dark-gray hover:text-new-black">
        Assembly Station
      </div>
    ),
  },
];

export const batteryTypeOptions = [
  {
    id: 1,
    value: "acceleron",
  },
  {
    id: 2,
    value: "fjshoan",
  },
];

export const threeDotsOptions = [
  {
    id: 1,
    value: "View Details",
    icon: <ViewDetailsIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 2,
    value: "Edit Details",
    icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
  },
  {
    id: 3,
    value: "Add to Service",
    icon: (
      <AddToServiceIcon className="text-dark-gray group-hover:text-black" />
    ),
  },
  {
    id: 4,
    value: "Delete Battery",
    icon: <TrashIcon className="text-alert-red" />,
  },
];
